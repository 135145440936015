import axios from 'axios';
import { printError } from '@/helpers/request';
import { useToast } from "vue-toastification";
import { refresh } from "@/helpers/auth";

const toast = useToast();

export async function setSkin(skin) {
    const formData = new FormData();
    formData.append('file', skin);
    await axios.post('/profile/skin', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(() => {
        refresh();
        toast.success('Ваш скин успешно обновлен');
    }).catch(printError);
}

export async function setCape(cape) {
    const formData = new FormData();
    formData.append('file', cape);
    await axios.post('/profile/cape', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(() => {
        toast.success('Ваш плащ успешно обновлен');
    }).catch(printError);
}

export async function getSavedSkins(name) {
   return await axios.get(`/skin/store/${name}/list`).then(({ data }) => {
        return data.map((id) => {
            return {
                id: id,
                skin: process.env.VUE_APP_URL_REST_API + '/skin/store/' + id
            };
        });
    }).catch(printError);
}

export async function getSavedCapes(name) {
    return await axios.get(`/cape/store/${name}/list`).then(({ data }) => {
        return data.map((id) => {
            return {
                id: id,
                cape: process.env.VUE_APP_URL_REST_API + '/cape/store/' + id
            };
        });
    }).catch(printError);
}

export async function deleteSavedSkin(id) {
    await axios.delete(`/profile/skin/store/${id}`).then(() => {
        toast.success('Скин успешно удален');
    }).catch(printError);
}

export async function deleteSavedCape(id) {
    await axios.delete(`/profile/cape/store/${id}`).then(() => {
        toast.success('Плащ успешно удален');
    }).catch(printError);
}

export async function setSavedSkin(id) {
    await axios.post(`/profile/skin/store/${id}`).then(() => {
        refresh();
        toast.success('Ваш скин успешно обновлен');
    }).catch(printError);
}

export async function setSavedCape(id) {
    await axios.post(`/profile/cape/store/${id}`).then(() => {
        toast.success('Ваш плащ успешно обновлен');
    }).catch(printError);
}